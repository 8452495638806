@import '../../vendor/styles/variables.css';

.accordion {
  padding: 0;

  &__section {
    padding: 0 16px;
    margin-bottom: 8px;
    background: var(--color-white);
  }

  &__title {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    align-items: center;
  }

  &__icon {
    display: block;
    float: right;
    transition: transform 375ms;
    height: 100%;
  }

  &__content {
    padding: 12px 0;
    border-top: 1px solid var(--color-quaternary);
  }
}

.animation-rotate {
  transform: rotate(180deg);
}
