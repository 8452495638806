@import '../../vendor/styles/variables.css';

.lp {
  position: relative;

  &__section {
    padding: 0 16px;
    margin: 24px 0;

    &__heading {
      position: relative;
      color: var(--color-secondary);
      padding: 0 0 32px;
      letter-spacing: 1px;
    }

    &__heading::after {
      position: absolute;
      left: 0;
      bottom: 16px;
      content: '';
      width: 32px;
      height: 1px;
      border-bottom: 2px solid var(--color-secondary);
    }
  }

  &__copyright {
    padding: 12px;
    background: var(--color-quinary);
  }
}
