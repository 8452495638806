/* @import '../../vendor/styles/variables.css'; */

.footer {
  background: #f3f1f8;

  &__container {
    padding: 0px;

    &__title {
      padding: 15px 21px 15px 12px;
      color: #252424;
      font-weight: 500;
      & i {
        color: #252424;
      }
    }

    &__section {
      border-bottom: 1px solid white;
    }
  }

  &__copyright {
    margin: 24px 0 16px;
  }
}

.footer-link {
  &__list {
    padding: 0 16px 12px;
  }

  &__item {
    padding: 14px 0;
  }

  &__text {
    margin-bottom: 0;
    color: #343434;
  }
}

.seo-footer {
  height: 8px;
  visibility: hidden;
  overflow: auto;
}
