@import '../../vendor/styles/variables.css';

.sd-overlay {
  background: #000;
  opacity: 0;
  transition: opacity 0.2s ease-in;

  &--visible {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1050;
    opacity: 0.65;
  }
}

.sd-drawer {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1050;
  width: 75%;
  background-color: #fff;
  transition: transform 0.2s ease-in;
  transform: translateX(-100%);

  &--open {
    transform: translateX(0%);
  }
}

.body--padded .sd-drawer {
  padding-top: 50px;
}
