@import '../../vendor/styles/variables.css';

.ts {
  padding: 27px 14px;
  border: 1px solid var(--color-quaternary);

  &__title {
    color: var(--color-secondary);
    font-weight: 500;
    margin-bottom: 0;
    position: relative;
    display: flex;
    align-items: center;

    &__icon {
      padding-right: 12px;
    }
  }

  &__icon {
    color: var(--color-secondary);
    font-family: 'icomoon' !important;
    font-size: 20px;
    position: absolute;
    top: 0;
    right: -8px;
    font-display: swap;
  }

  &__subtitle {
    margin-top: 8px;
    margin-bottom: 0;
  }

  &--border-both {
    border-width: 1px 0;
  }

  &--border-top {
    border-width: 1px 0 0;
  }

  &--border-bottom {
    border-width: 0 0 1px;
  }

  &--border-none {
    border-width: 0;
  }
}
