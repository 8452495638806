@import '../../vendor/styles/variables.css';

.nd {
  &__icon {
    position: absolute;
    top: 16px;
    right: 16px;
    color: black;
    font-size: 2.4rem;
    z-index: 1050;

    @media (--medium-screen) {
      font-size: 2.6rem;
    }
  }

  &__call {
    position: absolute;
    top: 18px;
    right: 64px;
    color: black;
    font-size: 2.4rem;
    z-index: 1050;

    @media (--medium-screen) {
      font-size: 2.6rem;
    }
  }

  &__container {
    padding: 16px;
  }

  &__version {
    bottom: 16px;
    width: 100%;
    position: absolute;
    text-align: center;
  }

  &__ts {
    padding: 16px 12px;

    &__title {
      font-size: 1.4rem;

      @media (--medium-screen) {
        font-size: 1.4rem;
      }
    }
  }
}

.ndg {
  &__header {
    text-align: center;
    background-color: var(--color-quinary);
  }

  &__subtitle {
    font-weight: 500;
    margin-bottom: 28px;
  }

  &__text {
    font-size: 1.4rem;

    @media (--medium-screen) {
      font-size: 1.6rem;
    }
  }

  &__img {
    margin-bottom: 24px;
  }
}

.ndm {
  position: relative;
  height: 100%;

  &__header {
    position: relative;
    padding: 24px 16px;
    margin: 0;
    border-bottom: 1px solid var(--color-quaternary);
    background: #f1f1f1;
  }

  &__footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 0 23px 23px;
    &__logout {
      color: var(--color-secondary);
    }
  }
}
