.carousel-slider {
  display: flex;
  flex-direction: column-reverse;
}

.rbb_slider .control-dots {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 2.4rem;
  flex-grow: 0;
  padding: 1rem 0.8rem 0.5rem;
  border-radius: 0 0 5px 5px;
  margin: 0px;

  &__dots {
    font-size: 0;
    line-height: 0;
    display: flex;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    padding: 0px 4px;

    &::before {
      content: '\f111';
      text-align: center;
      -webkit-font-smoothing: antialiased;
      border: none;
      border-radius: 100%;
      width: 8px;
      height: 8px;
      flex-grow: 0;
      background-color: #dedede;
    }
  }

  & li.active {
    &::before {
      width: 8px;
      height: 8px;
      flex-grow: 0;
      background-color: #797979;
    }
  }
}
