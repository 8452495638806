@import '../../../vendor/styles/variables.css';

.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 330px;
  background-color: #fff;

  .login {
    opacity: 0;
    animation: fadeIn 10s forwards;
  }

  &__content {
    padding: 16px;
  }

  &__topbar {
    margin-bottom: 58px;

    &__logo {
      width: 133px;
      height: 32px;
    }

    @media (--medium-screen) {
      margin-bottom: 30px;

      &__logo {
        height: 36px;
      }
    }
  }

  &__search {
    background: #fff;
    border-radius: 2px;
    box-shadow: var(--shadow-primary);

    &__text {
      font-size: 1.4rem;
      display: inline-block;
      padding: 10px;
      color: var(--color-tertiary);

      @media (--medium-screen) {
        font-size: 1.6rem;
        padding: 12px;
      }
    }

    &__icon {
      padding: 8px;
      color: #fff;
      background: var(--color-primary);
      float: right;
      border-radius: 2px;

      @media (--medium-screen) {
        font-size: 2.4rem;
        padding: 8px;
      }
    }
  }

  &__heading {
    color: #fff;
    font-weight: 300;
    font-size: 14px;
  }

  &__subheading {
    line-height: 1.5;
    color: #fff;
    margin-bottom: 20px;
    font-size: 1.5rem;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
