@import '../../vendor/styles/variables.css';

.slider {
  &__arrow {
    position: absolute;
    top: 50%;
    z-index: 1;
    color: var(--color-secondary);
    padding: 0;
    height: 4rem;
    width: 1.6rem;
    border-radius: 0.4rem;

    &--large {
      width: 3.2rem;
      height: 6.4rem;
      font-size: 2.4rem;
    }
 
    &--left {
      left: 0;
      transform: translate(-50%, -50%);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &--right {
      right: 0;
      transform: translate(50%, -50%);
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
