.ripple {
  overflow: hidden;
  position: relative;
  will-change: transform;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.4s, opacity 0.8s;
  }

  &:hover::after {
    opacity: 0;
  }

  &:active::after {
    transform: scale(0, 0);
    opacity: 0.2;
    transition: 0s;
  }
}
